<!-- @Author: xiaoyaqu -->
<!-- @Date: 2019-06-05 19:59:11.774 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-12 15:29:09 -->

<template>
    <div class="ec-footer">
        <div class="contact-info padding-top ec-container">
            <div class="top-wrapper">
                <div class="customer-left">
                    <div class="customer-item">
                        <img
                            src="./img/icons/easycash.svg"
                            alt="easycashlogo"
                            class="customer-item-logo"
                        >
                        <div class="contact-ec">
                            {{ $t('footer.contact') }}
                        </div>
                    </div>
                    <div
                        v-for="(item, index) in customerList"
                        :key="index"
                        class="customer-item customer-item-contact"
                    >
                        <div
                            class="customer-item-top"
                            :style="{'border-left': `2px solid ${item.color}`}"
                        >
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="contact">
                                <div
                                    v-for="(it, ix) in item.contact"
                                    :key="ix"
                                    class="contact-item"
                                    :style="{
                                        'min-width': it.minWidth
                                    }"
                                >
                                    <img
                                        :src="it.icon"
                                        class="customer-item-icon"
                                    >
                                    <a
                                        v-if="ix === 0"
                                        :href="`tel:${it.value}`"
                                    >{{ it.value }}</a>
                                    <a
                                        v-else-if="ix === 1"
                                        :href="`mailto:${it.value}`"
                                    >{{ it.value }}</a>
                                    <span v-else>{{ it.value }}</span>
                                </div>
                            </div>
                            <div
                                v-if="customerServiceTimeTip"
                                class="special-time"
                            >
                                {{ customerServiceTimeTip }}
                            </div>
                            <div
                                v-if="showWhatsapp"
                                class="contact whatsapp"
                            >
                                <div
                                    class="contact-item"
                                    :style="{
                                        width: '100%',
                                        marginTop: '0'
                                    }"
                                >
                                    <div
                                        v-if="isHoverBorrow && index === 0 || isHoverLend && index === 1"
                                        class="qr-wrap"
                                    >
                                        <img
                                            class="qr-code"
                                            :src="whatsappQr"
                                            alt=""
                                        >
                                    </div>
                                    <div class="whatsapp">
                                        <img
                                            src="./img/icons/whatsapp.svg"
                                            class="customer-item-icon"
                                        >
                                        <a
                                            :href="whatsappLink"
                                            @mouseenter="onMouseenter(index)"
                                            @mouseleave="onMouseleave(index)"
                                        >{{ whatsappNumber }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customer-item customer-item-social d-none d-md-block">
                        <div class="title">
                            {{ $t('footer.follow') }}
                        </div>
                        <div class="social-icon">
                            <div
                                class="icon icon-fa"
                                @click="handleSocial('facebook')"
                            />
                            <div
                                class="icon icon-in"
                                @click="handleSocial('instagram')"
                            />
                            <div
                                class="icon icon-tw"
                                @click="handleSocial('twitter')"
                            />
                            <div
                                class="icon icon-ti"
                                @click="handleSocial('tiktok')"
                            />
                            <div
                                class="icon icon-li"
                                @click="handleSocial('linkedin')"
                            />
                        </div>
                    </div>
                </div>
                <div class="customer-right">
                    <div class="map-wrapper">
                        <img
                            class="d-none d-md-block"
                            src="./img/icons/map.png"
                            alt="gmaplogo"
                            @click="handleSocial('googlemap')"
                        >
                        <img
                            class="d-block d-md-none"
                            src="./img/icons/map-sm.png"
                            alt="gmaplogo"
                            @click="handleSocial('googlemap')"
                        >
                        <div class="map-address">
                            <div
                                v-for="(item, index) in $t('footer.address.content')"
                                :key="index"
                            >
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="customer-item customer-item-social d-block d-md-none">
                        <div class="title">
                            {{ $t('footer.follow') }}
                        </div>
                        <div class="social-icon">
                            <div
                                class="icon icon-fa"
                                @click="handleSocial('facebook')"
                            />
                            <div
                                class="icon icon-in"
                                @click="handleSocial('instagram')"
                            />
                            <div
                                class="icon icon-tw"
                                @click="handleSocial('twitter')"
                            />
                            <div
                                class="icon icon-ti"
                                @click="handleSocial('tiktok')"
                            />
                            <div
                                class="icon icon-li"
                                @click="handleSocial('linkedin')"
                            />
                        </div>
                    </div>
                    <div
                        class="btn-wrapper"
                        :style="{
                            'justifyContent': $app.isIOS || $app.isAndroid ? 'center' : 'unset'
                        }"
                    >
                        <app-store-button
                            v-if="!$app.isIOS"
                            class="btn"
                            type="google"
                        />
                        <app-store-button
                            v-if="!$app.isAndroid"
                            class="btn"
                            type="apple-w"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="ec-container">
            <div class="seperator" />
        </div>

        <div class="contact-info">
            <div class="ec-container attention-text">
                <div class="attention">
                    {{ $t('footer.perhatian') }}
                </div>

                <div class="attention-content">
                    <div class="attention-content-item">
                        <div
                            v-for="(item, index) in $t('footer.perhatianTextMap')"
                            :key="index"
                        >
                            <div
                                v-if="index<4"
                                class="notice-info"
                            >
                                <span>{{ item }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="attention-content-item">
                        <ul
                            v-for="(item, index) in $t('footer.perhatianTextMap')"
                            :key="index"
                        >
                            <li
                                v-if="index>=4"
                                class="notice-info"
                            >
                                <span>{{ item }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="ec-container">
            <div class="seperator" />
        </div>

        <div class="contact-info">
            <div class="ec-container">
                <div class="copy-right">
                    <span>{{ $t('footer.copyRight', [new Date().getFullYear()]) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import {mapState} from 'vuex';

import businessData from 'easycash/common/mixin/business-data';

import AppStoreButton from './app-store-button';
import clock from './img/icons/clock.svg';
import email from './img/icons/email.svg';
import telephone from './img/icons/telephone.svg';

const SocialMap = {
    instagram: 'https://www.instagram.com/easycash.ind',
    facebook: 'https://www.facebook.com/EasycashIndonesia',
    tiktok: 'https://www.tiktok.com/@easycash.id',
    twitter: 'https://twitter.com/easycashid',
    linkedin: 'https://www.linkedin.com/company/fintopia-indonesia/',
    googlemap: 'https://goo.gl/maps/riaveCzX3VjM91XY7'
};

export default {
    name: 'EcFooter',

    components: {
        AppStoreButton
    },

    mixins: [businessData],

    data() {
        return {
            isHoverBorrow: false,
            isHoverLend: false
        };
    },

    computed: {
        ...mapState('contact', [
            'yqdContact',
            'finContact'
        ]),

        whatsappNumber() {
            const {whatsappLink} = this;

            const phone = whatsappLink?.split('phone=')?.[1]?.replace(/^62/, '0');

            return phone.slice(0, 4) + '-' + phone.slice(4);
        },

        whatsappLink() {
            return this.getBusinessData('whatsappCustomerServiceLink');
        },

        showWhatsapp() {
            return this.getBusinessData('showWhatsapp');
        },

        whatsappQr() {
            return this.getBusinessData('whatsappCustomerServiceQr');
        },

        customerServiceTimeTip() {
            const customerServiceTimeTip = this.getBusinessData('customerServiceHoursWebsite');
            const {locale, fallbackLocale} = this.$i18n;
            const localeFinal = locale === 'en' ? locale : fallbackLocale;

            return customerServiceTimeTip?.[localeFinal];
        },

        customerList() {
            return [
                {
                    title: this.$t('footer.borrower'),
                    color: '#03DA8B',
                    contact: [
                        {icon: telephone, value: this.$t('footer.customer.borrower')},
                        {icon: email, value: this.yqdContact.email, minWidth: '1.7rem'},
                        {icon: clock, value: this.$t('footer.workingHours.borrower')}
                    ]
                },
                {
                    title: this.$t('footer.lender'),
                    color: '#000AFF',
                    contact: [
                        {icon: telephone, value: this.$t('footer.customer.lender')},
                        {icon: email, value: this.finContact.email, minWidth: '1.7rem'},
                        {icon: clock, value: this.$t('footer.workingHours.lender')}
                    ]
                }
            ];
        }
    },

    methods: {
        handleSocial(key) {
            window.open(SocialMap[key]);
        },

        onMouseenter(idx) {
            this[idx === 0 ? 'isHoverBorrow' : 'isHoverLend'] = true;
        },

        onMouseleave(idx) {
            this[idx === 0 ? 'isHoverBorrow' : 'isHoverLend'] = false;
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~easycash/common/style/style";

    .ec-footer {
        background-color: #000;
        color: #fff;
        z-index: 5;

        .seperator {
            height: 1px;
            width: 100%;
            background-color: #dcdbdb;
        }

        .padding-top {
            padding-top: 0.6rem;

            @media screen and (max-width: $screen-md) {
                padding-top: 0.4rem;
            }
        }

        .contact-info {
            justify-content: center;

            .top-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-start;

                @media screen and (max-width: $screen-md) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .customer-left {
                    display: flex;
                    flex-direction: column;

                    @media screen and (max-width: $screen-md) {
                        padding-bottom: 0;
                    }

                    .customer-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 0.4rem;

                        @media screen and (max-width: $screen-md) {
                            margin-bottom: 0.2rem;

                            &:first-child,
                            &:last-child {
                                margin-bottom: 0.3rem;
                            }
                        }

                        .contact-ec {
                            font-weight: 700;
                            font-size: 0.18rem;
                        }

                        &-contact {
                            flex-direction: column;
                            align-items: flex-start;

                            .contact {
                                display: flex;
                                align-items: center;
                                margin-top: 0.1rem;
                                flex-wrap: wrap;

                                .contact-item {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    font-size: 0.12rem;

                                    a {
                                        color: #fff;
                                        cursor: pointer;
                                    }

                                    &:not(:last-child),
                                    .whatsapp {
                                        margin-right: 0.4rem;

                                        &:hover,
                                        &:active {
                                            opacity: 0.6;
                                        }
                                    }

                                    .whatsapp {
                                        display: flex;
                                    }

                                    .qr-wrap {
                                        border-radius: 0.16rem;
                                        width: 2rem;
                                        height: 2rem;
                                        position: absolute;
                                        left: 0;
                                        bottom: calc(100% + 0.15rem);
                                        box-sizing: border-box;
                                        padding: 0.15rem;
                                        background-color: #fff;
                                    }
                                }
                            }

                            .whatsapp {
                                margin-top: 0.04rem;

                                @media screen and (max-width: $screen-md) {
                                    margin-top: 0;
                                }
                            }

                            @media screen and (max-width: $screen-md) {
                                border-left: none !important;
                                padding-left: 0;

                                .contact {
                                    flex-wrap: wrap;

                                    .contact-item {
                                        margin-bottom: 0.1rem;

                                        a {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        &-top {
                            padding-left: 0.1rem;

                            @media screen and (max-width: $screen-md) {
                                border-left: none !important;
                                padding-left: 0;
                            }
                        }

                        &-social {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .title {
                            font-size: 0.18rem;
                            font-weight: 300;
                            line-height: 0.18rem;
                        }

                        &-logo {
                            height: 0.4rem;
                            width: 0.4rem;
                            margin-right: 0.1rem;
                        }

                        &-icon {
                            height: 0.16rem;
                            width: 0.16rem;
                            margin-right: 0.05rem;
                        }

                        .special-time {
                            position: relative;
                            max-width: 5.2rem;
                            margin-left: auto;
                            padding: 0.055rem 0.15rem 0.035rem;
                            background: rgba(63, 202, 88, 0.3);
                            border-radius: 0.05rem;
                            font-size: 0.12rem;
                            font-weight: 400;
                            line-height: 0.14rem;
                            letter-spacing: 0;
                            margin-top: 0.15rem;
                            margin-bottom: 0.1rem;

                            &::after {
                                position: absolute;
                                right: 0.3rem;
                                top: -0.1rem;
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 0.05rem solid transparent;
                                border-right: 0.05rem solid transparent;
                                border-bottom: 0.1rem solid rgba(63, 202, 88, 0.3);
                            }

                            @media screen and (max-width: $screen-md) {
                                padding: 0.09rem 0.1rem 0.07rem;
                                margin-top: 0;
                                margin-left: unset;
                                margin-right: auto;

                                &::after {
                                    left: 0.3rem;
                                    top: -0.08rem;
                                    border-left: 0.04rem solid transparent;
                                    border-right: 0.04rem solid transparent;
                                    border-bottom: 0.08rem solid rgba(63, 202, 88, 0.3);
                                }
                            }
                        }
                    }
                }

                .customer-right {
                    margin-top: 0.8rem;

                    @media screen and (max-width: $screen-md) {
                        margin-top: 0;
                    }

                    .map-wrapper {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 0.37rem;

                        img {
                            width: 2.4rem;
                            height: auto;
                            margin-right: 0.2rem;
                            cursor: pointer;
                            border-radius: 0.08rem;
                        }

                        .map-address {
                            width: 2.4rem;
                            font-size: 0.12rem;
                            line-height: 0.1875rem;
                        }

                        @media screen and (max-width: $screen-md) {
                            margin-bottom: 0.3rem;

                            img {
                                width: 1rem;
                                margin-right: 0.1rem;
                            }

                            .map-address {
                                width: auto;
                            }
                        }
                    }

                    .btn-wrapper {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 0.5rem;

                        .btn {
                            ::v-deep {
                                .channel-btn {
                                    width: 2.4rem;
                                    height: 0.48rem;
                                    margin-right: 0.2rem;
                                }
                            }
                        }

                        @media screen and (max-width: $screen-md) {
                            margin: 0.3rem 0;

                            .btn {
                                ::v-deep {
                                    .channel-btn {
                                        width: 1.65rem;
                                        height: 0.48rem;
                                        margin-right: 0.05rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .attention-text {
                margin-top: 0.6rem;
                margin-bottom: 0.5rem;

                @media screen and (max-width: $screen-md) {
                    margin-top: 0.3rem;
                    margin-bottom: 0.1rem;
                }

                .attention {
                    font-size: 0.18rem;
                    margin-bottom: 0.4rem;
                    text-align: left;
                    font-weight: 700;

                    @media screen and (max-width: $screen-md) {
                        margin-bottom: 0.1rem;
                    }
                }

                .attention-content {
                    display: flex;

                    &-item {
                        width: 50%;

                        &:first-child {
                            padding-right: 0.1rem;
                        }

                        &:last-child {
                            padding-left: 0.1rem;
                        }

                        .notice-info {
                            display: flex;
                            text-align: justify;
                            font-size: 0.12rem;
                            font-weight: 300;
                            line-height: 0.18rem;
                            margin-bottom: 0.2rem;

                            &::before {
                                content: "";
                                display: block;
                                width: 2px;
                                height: 2px;
                                border-radius: 50%;
                                background-color: #fff;
                                flex-shrink: 0;
                                margin-right: 0.08rem;
                                margin-top: 0.08rem;
                            }
                        }
                    }

                    @media screen and (max-width: $screen-md) {
                        flex-wrap: wrap;

                        &-item {
                            width: 100%;

                            &:first-child {
                                padding-right: 0;
                            }

                            &:last-child {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            .copy-right {
                padding: 0.3rem 0;
                text-align: center;
                font-size: 0.12rem;
                font-weight: 300;
            }
        }

        .customer-item-social {
            flex-direction: column;
            align-items: flex-start;

            .title {
                font-size: 0.18rem;
                font-weight: 300;
                line-height: 0.18rem;
            }

            .social-icon {
                display: flex;
                flex-direction: row;
                margin: 0.2rem 0 0.1rem;

                .icon {
                    width: 0.4rem;
                    height: 0.4rem;
                    margin-right: 0.2rem;
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;

                    @media screen and (max-width: $screen-md) {
                        margin-right: 0.1rem;
                    }
                }

                .icon-fa {
                    background-image: url("./img/icons/facebook.svg");

                    &:hover,
                    &:active {
                        background-image: url("./img/icons/facebook-a.svg");
                    }
                }

                .icon-in {
                    background-image: url("./img/icons/instagram.svg");

                    &:hover,
                    &:active {
                        background-image: url("./img/icons/instagram-a.svg");
                    }
                }

                .icon-tw {
                    background-image: url("./img/icons/twitter.svg");

                    &:hover,
                    &:active {
                        background-image: url("./img/icons/twitter-a.svg");
                    }
                }

                .icon-ti {
                    background-image: url("./img/icons/tiktok.svg");

                    &:hover,
                    &:active {
                        background-image: url("./img/icons/tiktok-a.svg");
                    }
                }

                .icon-li {
                    background-image: url("./img/icons/linkedin.svg");

                    &:hover,
                    &:active {
                        background-image: url("./img/icons/linkedin-a.svg");
                    }
                }
            }
        }
    }

</style>
